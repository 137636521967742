.wrapper {
  background-image: url(./assets/leftTopBg.png), url(./assets/rightTopBg.png),
    url(./assets/rightBottomBg.png),
    linear-gradient(to bottom, #fff5e9, #fff5e9);
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
  background-position: 2% 15%, 98% 0%, 98% 98%, left bottom;
  background-size: min(30%, 300px) auto, min(30%, 300px) auto,
    min(45%, 200px) auto, 100% 85%;
  background-origin: border-box, border-box, border-box, padding-box;
  padding: 150px 0px 100px;
}
