@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
}
@layer components {
  /* .d {
    @apply border-2 border-red;
  } */
  .container {
    @apply mx-auto;
  }
  .hero-btn {
    @apply py-2 px-8 border-2 border-white rounded-full  text-lg transition-colors duration-300 lg:text-xl font-bold hover:no-underline;
  }
  .blade-title {
    @apply text-3xl md:text-3xl lg:text-4xl 2xl:text-5xl;
  }
  .blade-y-margin {
    @apply my-10 md:my-16 lg:my-24;
  }
  .font-title {
    @apply text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl font-semibold tracking-widest;
  }
  .font-subtitle {
    @apply text-lg md:text-xl lg:text-2xl 2xl:text-3xl;
  }
  .para-text {
    @apply text-base lg:text-lg 2xl:text-xl  text-gray;
  }
  .catalogue-btn {
    @apply bg-red text-white z-[100] fixed py-2 md:px-10   bottom-4 right-4 md:bottom-10 md:right-10 font-bold 2xl:text-2xl  px-6 md:text-xl rounded-full border-2 border-white hover:outline-2 outline hover:no-underline  hover:outline-red hover:outline-offset-2;
  }
}
@layer base {
  a {
    @apply hover:underline  underline-offset-4;
  }
  * {
    @apply font-poppins;
  }
}
